<template>
    <div class="settings">
        <div class="settings__header">
            <div id="settings-header" class="settings__left-side"></div>
            <div class="settings__right-side">
                <CAction :actions="topActions" ignore-active-project-id/>
            </div>
        </div>
        <router-view/>
    </div>
    <InviteEmployeeModal :visible="inviteEmployeeModal" :redirect-to-list-after-saving="true"
                         @close="toggleInviteEmployeeModal"/>
</template>

<script>
import CAction from '@/components/CAction'
import Gate from '@/services/Gate/Gate'
import {permissionError} from '@/services/Notify/Toasts'
import InviteEmployeeModal from "@/components/Modals/InviteEmployeeModal.vue";

export default {
    name: 'Index',
    components: {InviteEmployeeModal, CAction},
    data() {
        return {
            inviteEmployeeModal: false,
        };
    },
    computed: {
        topActions() {
            return [
                {
                    label: 'Invite Employee',
                    event: () => this.goToInviteEmployee(),
                },
            ]
        },
    },
    methods: {
        async goToInviteEmployee() {
            (await Gate.can('create', 'employee'))
                ? this.toggleInviteEmployeeModal()
                : this.$notify(permissionError);
        },
        toggleInviteEmployeeModal() {
            this.inviteEmployeeModal = !this.inviteEmployeeModal;
        },
    },
}
</script>

<style lang="scss" scoped>
.settings {
    padding-bottom: 20px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
</style>
